import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { server } from '../server'

function getListQueryKey(constructionID: string) {
  return ['construction-ai-param', 'list', constructionID]
}

export function useConstructionAIParamsList(constructionID: string) {
  return useQuery({
    queryKey: getListQueryKey(constructionID),
    queryFn: () => server.listConstructionAiModelParams(constructionID),
    select: (data) => {
      return data.filter(
        (param) => 'rules' in param.params && param.params.rules.length > 0
      ).sort((a, b) => a.aiModelID - b.aiModelID)
    }
  })
}

export type ConstructionAIParamsCreateBody
  = Parameters<typeof server.createConstructionAiModelParams>[1]

export function useConstructionAIParamsCreate(constructionID: string) {
  const client = useQueryClient()
  return useMutation({
    mutationFn: (body: ConstructionAIParamsCreateBody) =>
      server.createConstructionAiModelParams(constructionID, body),
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getListQueryKey(constructionID),
      })
    },
  })
}

export type ConstructionAIParamsUpdateBody
  = Parameters<typeof server.updateConstructionAiModelParams>[1]

export function useConstructionAIParamsUpdate(params: {
  id: number
  constructionID: string
}) {
  const { id, constructionID } = params
  const client = useQueryClient()
  return useMutation({
    mutationFn: (body: ConstructionAIParamsUpdateBody) =>
      server.updateConstructionAiModelParams(id, body),
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getListQueryKey(constructionID),
      })
    },
  })
}

export function useConstructionAIParamsCopyOrgParams(constructionID: string) {
  const client = useQueryClient()
  return useMutation({
    mutationFn: (orgAIModelParamsID: number) =>
      server.copyOrgAiModelParamsToConstruction(
        constructionID,
        { orgAIModelParamsID },
      ),
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getListQueryKey(constructionID),
      })
    },
  })
}

export function useConstructionAIParamsDelete(constructionID: string) {
  const client = useQueryClient()
  return useMutation({
    mutationFn: (id: number) =>
      server.deleteConstructionAiModelParams(id),
    onSuccess: async () => {
      await client.invalidateQueries({
        queryKey: getListQueryKey(constructionID),
      })
    },
  })
}
