import type { ReactElement } from 'react'
import {
  Button,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Field,
  OverlaySpinner,
  Select,
  Subtitle2,
  makeStyles,
  tokens,
  useIntl,
} from '@aisekisan/bond'
import {
  ApiError,
  parseServerError,
  useAIModelList,
  useConstructionAIParamsCopyOrgParams,
  useConstructionAIParamsList,
  useOrgAIParamsList,
} from '@aisekisan/anya-api'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Dismiss24Regular } from '@fluentui/react-icons'
import { useT } from '@/libs/intl/useT'
import { ErrorAlert } from '@/libs/ui/ErrorAlert'
import { T } from '@/libs/intl/t'
import { OrgAIParamListEquipment } from '@/components/org/ai-param/list/equipment'

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalL,
  },
  equip: {
    maxHeight: '30vh',
    overflowY: 'auto',
  },
  actions: {
    alignSelf: 'flex-end',
  },
})

interface FormBody {
  id: string
  aiModelID: string
}

export function ConstructionAIParamsCopy(props: {
  orgID: string
  constructionID: string
  close: () => void
  updateSelectedModel: (aiModelID: number) => void
}): ReactElement {
  const { orgID, constructionID, close, updateSelectedModel } = props

  const t = useT()
  const styles = useStyles()
  const { lang } = useIntl()

  const orgParams = useOrgAIParamsList(orgID).data ?? []
  const constructionParams = useConstructionAIParamsList(constructionID).data ?? []
  const aiModels = useAIModelList().data ?? []

  const form = useForm<FormBody>({
    defaultValues: { id: '', aiModelID: '' },
    resolver: zodResolver(z.object({
      id: z.string().min(1, t('construction.ai-params.required')),
      aiModelID: z.string()
        .min(1, t('construction.ai-params.required'))
        .superRefine((id, ctx) => {
          if (constructionParams.some(item => item.aiModelID.toString() === id)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('construction.ai-params.ai-model-duplicate'),
            })
          }
        }),
    })),
  })

  const { formState: { errors } } = form

  const aiModelID = form.watch('aiModelID')
  const paramsID = form.watch('id')

  const aiModelParams = orgParams.find(({ id }) => id.toString() === paramsID)

  const copyOrgParams = useConstructionAIParamsCopyOrgParams(constructionID)
  const onSubmit = (values: FormBody) => {
    if (values.id === '')
      return
    copyOrgParams.mutate(Number.parseInt(values.id), { onSuccess: () => {
      close()
      updateSelectedModel(Number.parseInt(values.aiModelID))
    } })
  }

  const getErrorMessage = () => {
    if (copyOrgParams.error === null)
      return ''
    if (copyOrgParams.error instanceof ApiError) {
      switch (copyOrgParams.error.status) {
        case 403:
          return t('ai-param.error-403.help')
        case 400:
          return t('ai-param.error-400.help')
      }
    }
    return t('all.error.retry')
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className={styles.form}>
      <DialogBody>
        <DialogTitle
          action={(
            <DialogTrigger action="close">
              <Button
                appearance="subtle"
                aria-label="close"
                icon={<Dismiss24Regular />}
              />
            </DialogTrigger>
          )}
        >
          <T id="construction.ai-param.create.title" />
        </DialogTitle>
        <DialogContent>
          <OverlaySpinner visible={copyOrgParams.isPending} appearance="primary" />
          {copyOrgParams.isError
            ? (
                <div>
                  <ErrorAlert
                    title={t(`construction.ai-param.create.error`)}
                    help={getErrorMessage()}
                    detail={parseServerError(copyOrgParams.error)}
                    onClose={copyOrgParams.reset}
                  />
                </div>
              )
            : null}
          <Field
            label={(
              <Subtitle2>
                <T id="construction.ai-param.copy.ai-model" />
              </Subtitle2>
            )}
            validationState={errors.aiModelID ? 'error' : 'none'}
            validationMessage={errors.aiModelID?.message}
          >
            <Select
              appearance="filled-darker"
              value={aiModelID}
              onChange={(_, data) => {
                form.setValue('aiModelID', data.value, { shouldValidate: true })
                form.setValue('id', '')
              }}
            >
              <option value="" hidden></option>
              {aiModels
                .filter(({ locale, ai_model_id: id }) => {
                  return locale === lang
                    && orgParams.some(params => params.aiModelID === id)
                })
                .sort((a, b) => a.ai_model_id - b.ai_model_id)
                .map(model => (
                  <option key={model.ai_model_id} value={model.ai_model_id}>
                    {model.localed}
                  </option>
                ))}
            </Select>
          </Field>
          <Field
            label={(
              <Subtitle2>
                <T id="construction.ai-param.copy.rule" />
              </Subtitle2>
            )}
            validationState={errors.id ? 'error' : 'none'}
            validationMessage={errors.id?.message}
          >
            <Select
              appearance="filled-darker"
              {...form.register('id')}
            >
              <option value="" hidden></option>
              {orgParams
                .filter((params => params.aiModelID.toString() === aiModelID))
                .map(({ id, displayName }) => (
                  <option key={id} value={id}>
                    {displayName === ''
                      ? <T id="org.ai-param.display-name.empty" />
                      : displayName}
                  </option>
                ))}
            </Select>
          </Field>
          {/* for FireProtectionPipeDiameterParams only */}
          {aiModelParams && 'rules' in aiModelParams.params && (
            <div key={aiModelParams.id} className={styles.equip}>
              <OrgAIParamListEquipment
                params={aiModelParams.params}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions className={styles.actions}>
          <DialogTrigger disableButtonEnhancement>
            <Button>
              <T id="construction.ai-param.copy.cancel" />
            </Button>
          </DialogTrigger>
          <Button appearance="primary" type="submit">
            <T id="construction.ai-param.create.submit" />
          </Button>
        </DialogActions>
      </DialogBody>
    </form>
  )
}
