import { type ConstructionMemberDetail, useAccountInfo, useConstructionMemberList, useOrgMemberList } from '@aisekisan/anya-api'
import {
  Body1,
  Button,
  Panel,
  makeStyles,
  shorthands,
  tokens,
} from '@aisekisan/bond'
import { People24Regular, PersonAddRegular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { ConstructionMemberAdd } from './add'
import { ConstructionMemberRemove } from './remove'
import { ConstructionMemberTable } from './table'

import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  table: {
    marginBottom: tokens.spacingVerticalL,
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.padding(tokens.spacingHorizontalL),
    // Override shorthand padding
    paddingTop: 0,
  },
  help: {
    color: tokens.colorNeutralForeground3,
  },
})

interface Props {
  constructionID: string
  orgID: string
}

export function ConstructionMemberList(props: Props): ReactElement {
  const { constructionID, orgID } = props

  const styles = useStyles()

  const [removeMember, setRemoveMember] = useState<ConstructionMemberDetail | null>(null)
  const [addMember, setAddMember] = useState<boolean>(false)

  const account = useAccountInfo().data
  const memberList = useConstructionMemberList({ constructionID }).data ?? []

  const orgMemberList = useOrgMemberList({ orgID }).data ?? []

  const orgRole = orgMemberList.find(member => member.email === account?.email)?.role ?? 'member'
  const role = memberList.find(member => member.email === account?.email)?.role ?? 'viewer'

  return (
    <Panel
      title={<T id="construction.member.list.title" />}
      icon={<People24Regular />}
      actions={(
        <Button icon={<PersonAddRegular />} onClick={() => setAddMember(true)}>
          <T id="construction.member.add.title" />
        </Button>
      )}
    >
      {addMember && (
        <ConstructionMemberAdd
          constructionID={constructionID}
          orgID={orgID}
          close={() => setAddMember(false)}
          role={role}
          orgRole={orgRole}
        />
      )}
      {removeMember && (role === 'owner' || orgRole === 'owner') && (
        <ConstructionMemberRemove
          constructionID={constructionID}
          close={() => setRemoveMember(null)}
          account={removeMember}
        />
      )}
      <Panel className={styles.table}>
        <div>
          <ConstructionMemberTable
            constructionID={constructionID}
            memberList={memberList}
            setRemove={member => void setRemoveMember(member)}
            locale={account?.locale ?? 'ja'}
          />
        </div>
      </Panel>
      <Body1 className={styles.help}>
        <T id="construction.member.add.guideline" />
        <ul style={{ margin: 0 }}>
          <li>
            <T id="construction.member.add.guideline.1" />
          </li>
          <li>
            <T id="construction.member.add.guideline.2" />
          </li>
        </ul>
      </Body1>
    </Panel>
  )
}
