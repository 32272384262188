import type { TabValue } from '@aisekisan/bond'
import {
  Card,
  Divider,
  Tab,
  TabList,
  makeStyles,
  tokens,
  useIntl,
} from '@aisekisan/bond'
import type { ReactElement, ReactNode } from 'react'
import { useState } from 'react'
import {
  type FireProtectionPipeDiameterParams,
  useEquipmentClasses,
} from '@aisekisan/anya-api'
import { ConstructionAIParamListFormula } from './formula'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    columnGap: tokens.spacingHorizontalS,
  },
  divider: {
    flexGrow: 'initial',
  },
  table: {
    width: '100%',
  },
})

export function ConstructionAIParamListEquipment(props: {
  params: FireProtectionPipeDiameterParams
  actions?: ReactNode
}): ReactElement | null {
  const { params, actions } = props

  const { lang } = useIntl()
  const styles = useStyles()

  const [selectedValue, setSelectedValue] = useState<TabValue>(
    params.rules[0]?.equipment_class,
  )

  const equipmentClasses = useEquipmentClasses().data ?? []

  return (
    <Card>
      <div className={styles.container}>
        <TabList
          vertical
          selectedValue={selectedValue}
          onTabSelect={(_, data) => setSelectedValue(data.value)}
        >
          {params.rules.map(({ equipment_class }) => {
            const equip = equipmentClasses.find(({ equipmentClass, locale }) => {
              return equipment_class === equipmentClass && locale === lang
            })
            return (
              <Tab key={equipment_class} value={equipment_class}>
                {equip?.localed}
              </Tab>
            )
          })}
          {actions}
        </TabList>
        <Divider vertical className={styles.divider} />
        <div className={styles.table}>
          {params.rules.map((rule) => {
            if (rule.equipment_class !== selectedValue)
              return null
            return (
              <ConstructionAIParamListFormula
                formulas={rule.formulas}
                key={rule.equipment_class}
              />
            )
          })}
        </div>
      </div>
    </Card>
  )
}
