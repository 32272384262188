import type {
  AIModelParams,
  OrgAIModelParamsDetail,
} from '@aisekisan/anya-api'

export interface OrgAIParamsFormFormulaBody {
  id: string
  from: string
  to: string
  diameter: string
}

export interface OrgAIParamsFormRuleBody {
  id: string
  equipmentClass: string
  formulas: OrgAIParamsFormFormulaBody[]
}

export interface OrgAIParamsFormBody {
  aiModelID: string
  hydrantRule: {
    equipmentClass: string
    exchangeValue: string
  }
  rules: OrgAIParamsFormRuleBody[]
  displayName: string
}

export function parseAIModelParamsServer(
  data: OrgAIModelParamsDetail,
): OrgAIParamsFormBody {
  const aiModelID = data.aiModelID.toString()

  // check if data.params is FireProtectionPipeDiameterParams
  if ('rules' in data.params) {
    const hydrantRule = {
      equipmentClass: data.params.hydrant_rule.equipment_class.toString(),
      exchangeValue: data.params.hydrant_rule.exchange_value.toString(),
    }
    const rules = data.params.rules.map((rule) => {
      return {
        id: crypto.randomUUID(),
        equipmentClass: rule.equipment_class.toString(),
        formulas: rule.formulas.map((formula) => {
          return {
            id: crypto.randomUUID(),
            from: formula.num_head_from.toString(),
            to: formula.num_head_to.toString(),
            diameter: formula.diameter.toString(),
          }
        }),
      }
    })
    const displayName = data.displayName
    return { aiModelID, hydrantRule, rules, displayName }
  }

  return {
    aiModelID,
    hydrantRule: {
      equipmentClass: '',
      exchangeValue: '',
    },
    rules: [],
    displayName: '',
  }
}

export function toAIModelParamsServer(
  data: OrgAIParamsFormBody,
): { aiModelID: number, params: AIModelParams, displayName: string } {
  const aiModelID = Number.parseInt(data.aiModelID)
  const hydrant_rule = {
    equipment_class: Number.parseInt(data.hydrantRule.equipmentClass),
    exchange_value: Number.parseInt(data.hydrantRule.exchangeValue),
  }
  const rules = data.rules.map((rule) => {
    return {
      equipment_class: Number.parseInt(rule.equipmentClass),
      formulas: rule.formulas.map((formula) => {
        return {
          num_head_from: Number.parseInt(formula.from),
          num_head_to: Number.parseInt(formula.to),
          diameter: Number.parseInt(formula.diameter),
        }
      }),
    }
  })
  const displayName = data.displayName
  return { aiModelID, params: { rules, hydrant_rule }, displayName }
}
