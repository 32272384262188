import type { TabValue } from '@aisekisan/bond'
import {
  Card,
  Tab,
  TabList,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { type ReactElement, useState } from 'react'
import type {
  FireProtectionPipeDiameterParams,
  OrgAIModelParamsDetail,
} from '@aisekisan/anya-api'
import { OrgAIParamsEdit } from '../edit'
import { OrgAIParamsDelete } from '../delete'
import { OrgAIParamListEquipment } from './equipment'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  tabList: {
    paddingBottom: tokens.spacingHorizontalS,
    maxWidth: '100%',
    overflowX: 'auto',
  },
  actions: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalS,
    paddingTop: tokens.spacingHorizontalS,
  },
})

export function OrgAIParamListRules(props: {
  aiModelRules: OrgAIModelParamsDetail[]
}): ReactElement {
  const { aiModelRules } = props

  const styles = useStyles()

  const [selectedRule, setSelectedRule] = useState<TabValue>(
    aiModelRules[0]?.id,
  )

  return (
    <div>
      <TabList
        className={styles.tabList}
        selectedValue={selectedRule}
        onTabSelect={(_, data) => setSelectedRule(data.value)}
      >
        {aiModelRules.map(({ id, displayName }) => {
          return (
            <Tab key={id} value={id}>
              {displayName === ''
                ? <T id="org.ai-param.display-name.empty" />
                : displayName}
            </Tab>
          )
        })}
      </TabList>
      <Card>
        {aiModelRules
          // check if data.params is FireProtectionPipeDiameterParams
          .filter(params => 'rules' in params.params && params.params.rules.length > 0)
          .map((params) => {
            if ('rules' in params.params === false)
              return null
            if (params.id !== selectedRule)
              return null
            return (
              <OrgAIParamListEquipment
                key={params.id + (params.params as FireProtectionPipeDiameterParams).rules.length} // // Unmount component when add/delete rules sets
                params={params.params as FireProtectionPipeDiameterParams}
                actions={(
                  <div className={styles.actions}>
                    <OrgAIParamsEdit aiModelParams={params} />
                    <OrgAIParamsDelete aiModelParams={params} />
                  </div>
                )}
              />
            )
          })}
      </Card>
    </div>
  )
}
