import {
  Button,
  OverlaySpinner,
  Title3,
  makeStyles,
  tokens,
} from '@aisekisan/bond'
import { DeleteRegular, EditRegular } from '@fluentui/react-icons'
import { useState } from 'react'
import { useAccountInfo, useConstructionDetail, useOrgDetail, useOrgMemberList } from '@aisekisan/anya-api'
import { AppPage } from '@/AppPage'
import { ContainerWrapper } from '@/ContainerWrapper'
import { CategoryList } from '@/components/category/list'
import { ConstructionEquipmentPropertyList } from '@/components/construction/property/list'
import { ConstructionMemberList } from '@/components/construction/member/list'
import { T } from '@/libs/intl/t'
import { useT } from '@/libs/intl/useT'
import { ConstructionEdit } from '@/components/construction/edit'
import { ConstructionDelete } from '@/components/construction/delete'
import { NotFound } from '@/components/not-found'
import { ConstructionModuleList } from '@/components/construction/module/list'
import { useConstructionRole } from '@/components/construction/member/role'
import { ConstructionAIParamList } from '@/components/construction/ai-param/list/list'
import { ConstructionZoneList } from '@/components/construction/zone/list'

const useStyles = makeStyles({
  title: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: tokens.spacingHorizontalL,
    alignItems: 'flex-start',
  },
  code: {
    color: tokens.colorNeutralForeground3,
  },
})

export function ConstructionDetailPage(props: { id: string }): JSX.Element {
  const { id } = props

  const t = useT()
  const styles = useStyles()

  const [edit, setEdit] = useState<boolean>(false)
  const [deleteConstruction, setDeleteConstruction] = useState<boolean>(false)

  const detail = useConstructionDetail({ constructionID: id })
  const data = detail.data

  const orgID = data?.orgID ?? ''
  const org = useOrgDetail({ orgID }).data

  const constructionRole = useConstructionRole(id)

  // Check user role in org
  const account = useAccountInfo().data
  const memberList = useOrgMemberList({ orgID }).data ?? []
  const orgRole = memberList.find(member => member.email === account?.email)?.role ?? 'member'

  if (detail.isLoading)
    return <OverlaySpinner visible={detail.isLoading} />

  return (
    <AppPage
      breadcrumbs={data
        ? [
            {
              title: `${t('org.title')} ${org?.name ?? ''}`,
              href: `/org/${org?.id}`,
            },
            {
              title: `${t('construction.title')} ${data.name}`,
              href: '',
            },
          ]
        : []}
      title={(
        <div className={styles.title}>
          <Title3 className={styles.code}>{data?.code}</Title3>
          <Title3>{data?.name}</Title3>
          {data && (constructionRole === 'owner' || orgRole === 'owner') && (
            <>
              <Button icon={<EditRegular />} onClick={() => setEdit(true)}>
                <T id="construction.information.edit" />
              </Button>
              <Button icon={<DeleteRegular />} onClick={() => setDeleteConstruction(true)}>
                <T id="construction.action.delete" />
              </Button>
            </>
          )}
        </div>
      )}
    >
      {detail.isError && <NotFound />}

      {data
      && (
        <>
          <ContainerWrapper>
            <CategoryList constructionID={id} />
          </ContainerWrapper>
          <ContainerWrapper>
            <ConstructionEquipmentPropertyList constructionID={id} />
            <ConstructionMemberList constructionID={id} orgID={data.orgID} />
            <ConstructionModuleList constructionID={id} />
            <ConstructionAIParamList constructionID={id} orgID={data.orgID} />
            <ConstructionZoneList constructionID={id} />
            {edit && <ConstructionEdit construction={data} close={() => setEdit(false)} />}
            {deleteConstruction && <ConstructionDelete close={() => setDeleteConstruction(false)} construction={data} orgID={data.orgID} />}
          </ContainerWrapper>
        </>
      )}
    </AppPage>
  )
}
