import type { IntlDict } from './type'

export const INTL_DICT_JA: IntlDict = {
  'account-info.otp.scan-qr': 'QRコードをスキャン',
  'account-info.otp.verify': 'ワンタイムパスワード認証',
  'account-info.title': 'アカウント情報',
  'account-info.update.submit': '更新する',
  'account-info.update.title': '個人情報を更新する',
  'account.field.company-name': '企業名',
  'account.field.email': 'メールアドレス',
  'account.field.first-name-kana': '名 (カナ)',
  'account.field.first-name': '名',
  'account.field.full-name-kana': '氏名（カナ）',
  'account.field.full-name': '氏名',
  'account.field.last-name-kana': '性 (カナ)',
  'account.field.last-name': '性',
  'account.field.locale': '言語設定',
  'account.field.name': '氏名',
  'account.field.password.helper': 'パスワードは10文字以上が必須です。',
  'account.field.password': 'パスワード',
  'account.field.phone': '電話番号',
  'account.info.title': '個人情報',
  'account.info.update': '更新',
  'account.manage.security.2FA': '2 要素認証',
  'account.manage.security.helper': 'アカウントを保護するためにセキュリティを設定してください。',
  'account.manage.security.title': 'セキュリティ',
  'account.manage.title': 'アカウント管理',
  'account.reset-password.description.1': '新しいパスワードを作成してください。',
  'account.reset-password.description.2': ' 新しいパスワードが作成されると、ログインページに移動されます。新しいパスワードでログインしてください。',
  'account.signout': 'ログアウト',
  'ai-param.error-400.help': 'フォームの送信内容を確認して、もう一度お試しください。',
  'ai-param.error-403.help': '許可が拒否されました。',
  'ai-param.error-404.help': 'この AI パラメータは存在しません',
  'all.error.retry': '再度除外するかサポートにお問い合わせください。',
  'all.error.review': '入力内容に誤りがあります。もう一度ご入力ください。',
  'annotation.field.module': '系統',
  'annotation.module.create.title': '系統追加',
  'annotation.module.edit.submit': '修正',
  'annotation.module.edit.title': '系統修正',
  'app.header.user-guide': 'ユーザーマニュアル',
  'app.name.sekisan': 'AI積算',
  'app.notify.hide': '非表示',
  'app.notify.learn-more': '詳細情報',
  'app.notify.update-version': 'アップデート版: ',
  'category.action.delete': '削除',
  'category.add-pages.pages': 'ページを選択',
  'category.categories.label': '工事種目',
  'category.create.error': '工事種目を追加できませんでした',
  'category.create.field.name': '工事種目',
  'category.create.field.parentID.hint': '工事種目の中に新しい種目を追加できます',
  'category.create.field.parentID': '親種目',
  'category.create.submit': '追加する',
  'category.create.title': '工事種目を追加する',
  'category.create.top-level': 'トップ',
  'category.delete.error.title': 'この工事種目が削除できません',
  'category.delete.help': 'この工事種目を削除してもよろしいですか?',
  'category.delete.helper': 'この工事種目を削除してもよろしいですか？ ',
  'category.delete.rules.1': '全ての図面',
  'category.delete.rules.2': '全ての拾いデータ',
  'category.delete.rules.3': '削除した後は戻すことができません。 削除を確認するため、工事種目を入力してください。',
  'category.delete.rules': '次のような関連データがすべて削除されます：',
  'category.delete.title': '工事種目削除',
  'category.edit.cancel': 'キャンセル',
  'category.edit.submit': '編集',
  'category.edit.title': '工事種目編集',
  'category.field.name': '名称',
  'category.information.edit': '編集',
  'category.label': '工事種目',
  'category.list.create': '工事種目の追加',
  'category.list.filter.all_pages': '全てのページ',
  'category.list.filter.equipment': '部材',
  'category.list.filter.with_annotations': '拾い済みページ',
  'category.list.filter.without_annotations': '未拾いページ',
  'category.list.report.annotations': '図面フィルター',
  'category.list.title': '工事種目',
  'category.page.rename': '名称',
  'conctruction.delete.close-quote': '」',
  'conctruction.delete.open-quote': '「',
  'construction.action.delete': '削除',
  'construction.ai-param.ai-model-duplicate': 'AIモデルはすでに存在します',
  'construction.ai-param.ai-model.error': 'AIモデルはすでに存在します',
  'construction.ai-param.ai-model.field': 'AIモデル',
  'construction.ai-param.copy.action': 'デフォルトのパラメータを使用する',
  'construction.ai-param.copy.ai-model': 'AIモデル',
  'construction.ai-param.copy.cancel': 'キャンセル',
  'construction.ai-param.copy.hint': 'このアクションにより、デフォルトの AI モデル パラメーターが構築に追加されます。 AI モデルのパラメーターがすでに存在する場合、それらは変わりません。続行しますか?',
  'construction.ai-param.copy.rule': 'ルール',
  'construction.ai-param.create.action': 'パラメータの追加',
  'construction.ai-param.create.error': 'AI パラメータを作成できませんでした',
  'construction.ai-param.create.submit': '作成',
  'construction.ai-param.create.title': 'パラメータの追加',
  'construction.ai-param.delete.action': '削除',
  'construction.ai-param.delete.cancel': 'キャンセル',
  'construction.ai-param.delete.error': 'AI パラメータを削除できませんでした',
  'construction.ai-param.delete.hint': 'このアクションにより、この AI モデルのパラメーターが構築から削除されます。この操作は元に戻すことができません。続行しますか?',
  'construction.ai-param.delete.submit': '確認する',
  'construction.ai-param.delete.title': 'AIパラメータの削除',
  'construction.ai-param.diameter.field': '直径',
  'construction.ai-param.equipment-class-duplicate': '設備クラスはすでに存在します',
  'construction.ai-param.form.action': 'パラメータを手動で作成する',
  'construction.ai-param.form.cancel': 'キャンセル',
  'construction.ai-param.guideline.1': '1 つの AI モデルには 1 セットのパラメーターのみを含めることができます。',
  'construction.ai-param.guideline.2': 'メンバーは、組織 AI パラメーターからパラメーターをコピーして、より速く開始したり、手動で AI パラメーターを作成したりできます。',
  'construction.ai-param.guideline': 'AI パラメータは、AI を開始するときにエディタでデフォルトの AI パラメータとして使用されます。',
  'construction.ai-param.hydrant-rule.equipment': '設備',
  'construction.ai-param.hydrant-rule.exchange-value': '交換価値',
  'construction.ai-param.hydrant-rule.title': '消火栓ルール',
  'construction.ai-param.invalid': '無効',
  'construction.ai-param.list.diameter': '直径',
  'construction.ai-param.list.num-head': 'ヘッドの数',
  'construction.ai-param.list.range {{.from}} {{.to}}': '{{.from}}から{{.to}}まで',
  'construction.ai-param.list.title': 'ルールブック',
  'construction.ai-param.manual.action': 'パラメータを手動で作成する',
  'construction.ai-param.max.field': '最大',
  'construction.ai-param.min.field': '最小',
  'construction.ai-param.overlap': '重なり',
  'construction.ai-param.required': '必須',
  'construction.ai-param.rule.equipment.error': '設備クラスはすでに存在します',
  'construction.ai-param.rule.equipment.field': '設備',
  'construction.ai-param.rule.title': 'ルール',
  'construction.ai-param.update.action': '編集',
  'construction.ai-param.update.error': 'AIパラメータを更新できませんでした',
  'construction.ai-param.update.submit': '更新',
  'construction.ai-param.update.title': 'AIパラメータの更新',
  'construction.ai-params.ai-model-duplicate': 'この AI モデルのルールはすでに存在します。別の AI モデルを選択するか、既存のルールを編集してください',
  'construction.ai-params.equipment-class-duplicate': '設備クラスはすでに存在します',
  'construction.ai-params.invalid': '無効',
  'construction.ai-params.overlap': '重なり',
  'construction.ai-params.required': '必須',
  'construction.ai-params.rule.title {{.index}}': 'ルール{{.index}}',
  'construction.categories.empty': '工事種目なし',
  'construction.category.action.annotate': '拾出し',
  'construction.category.action.edit': '編集',
  'construction.category.annotations': '部材',
  'construction.category.pages': '図面',
  'construction.category': '工事種目',
  'construction.create.submit': '作成する',
  'construction.create.title': '案件を作成する',
  'construction.delete.error-field': '正確な案件名称を入力することが必須です',
  'construction.delete.error.title': 'この案件が削除できません',
  'construction.delete.help': 'この案件を削除してもよろしいですか?',
  'construction.delete.rules.1': '全ての工事種目',
  'construction.delete.rules.2': '全ての図面',
  'construction.delete.rules.3': '全ての拾いデータ',
  'construction.delete.rules.4 {{.name}}': '削除したデータは復元することができません。 削除を確認するため、案件名称{{.name}}を入力してください。',
  'construction.delete.rules': 'の関連データがすべて削除されます：',
  'construction.delete.title': '案件削除',
  'construction.edit.title': '編集',
  'construction.explore.title': '選択',
  'construction.field.category': '工事種目',
  'construction.field.code': '案件番号',
  'construction.field.name': '案件名称',
  'construction.field.preset': 'プリセット',
  'construction.information.edit': '編集',
  'construction.information.overview': '概要',
  'construction.invitation.create.role-viewer': 'あなたの権限では他のメンバーを招待できません。本案件のオーナーに依頼してください。',
  'construction.list.create': '案件を作成',
  'construction.list.title': '案件一覧',
  'construction.member.add.error': 'メンバーを追加できませんでした',
  'construction.member.add.guideline.1': '「編集者」は工事種目を追加したり、図面を修正したり、資材を拾ったりすることができます',
  'construction.member.add.guideline.2': '「閲覧者」は拾い図面をチェックしたり、拾い表をチェックしたり、拾い結果をダウンロードしたりすることができます。',
  'construction.member.add.guideline': 'この案件に他のメンバーを組織から追加してください:',
  'construction.member.add.hint.1': 'この案件のロール：',
  'construction.member.add.hint.2': '「編集者」は工事種目を追加したり、図面を修正したり、資材を拾ったりすることができます',
  'construction.member.add.hint.3': '「閲覧者」は拾い図面をチェックしたり、拾い表をチェックしたり、拾い結果をダウンロードしたりすることができます。',
  'construction.member.add.hint': '同様の組織のメンバーのみを追加できます。 他のアカウントを追加するには、先に組織に参加するように招待ください。',
  'construction.member.add.member': 'メンバー',
  'construction.member.add.submit': '追加する',
  'construction.member.add.title': 'メンバーを追加する',
  'construction.member.editors': '編集者',
  'construction.member.list.title': 'ユーザー一覧',
  'construction.member.remove.cancel': 'キャンセル',
  'construction.member.remove.error.title': 'このメンバーを除外できません',
  'construction.member.remove.helper {{.email}}': ' この案件から {{.email}} を除外してもよろしいですか ?',
  'construction.member.remove.helper.2': 'このメンバーを除外しますと、案件の図面を編集したり、拾い作業したりすることができなくなります。',
  'construction.member.remove.helper.3': 'ただし、組織のメンバーであることに変わりはなく、将来的にこの案件に再び追加することができます。',
  'construction.member.remove.submit': '除外する',
  'construction.member.remove': 'メンバーを除外されますか?',
  'construction.module.create.error': '系統を作成できませんでした',
  'construction.module.create.submit': '作成する',
  'construction.module.edit.error': '系統が修正できません',
  'construction.module.field.name': '系統',
  'construction.module.field.quantity': '係数',
  'construction.preset.create': 'プリセット作成',
  'construction.preset.helper.1': 'プリセットは、事前に設定された部材の仕様、施工箇所などのプロパティ含むファイルです。',
  'construction.preset.helper.2': 'プリセットを使用すると、資材拾い作業を高速化できます。',
  'construction.preset.helper.3': '案件を作成した後はプリセットを選択できません。',
  'construction.preset.list.title': 'プリセット一覧',
  'construction.property.create': 'プロパティ作成',
  'construction.property.update-successfully': '資材のプロパティを更新しました。',
  'construction.role.editor.title': '編集者',
  'construction.role.owner.title': 'オーナー',
  'construction.role.viewer.title': '閲覧者',
  'construction.title {{.name}}': '案件名称：{{.name}}',
  'construction.title': '案件一覧',
  'construction.update.error': '案件情報を更新できませんでした。',
  'construction.update.submit': '更新',
  'construction.update.title': '案件を更新する',
  'dialog.action.cancel': 'キャンセル',
  'error.message': 'エラーが発生しました。 もう一度試してください',
  'file.error.message': 'ファイルは必須です',
  'form.error.required_field': 'この項目は必須です',
  'Greetings {{.name}}': 'こんにちは {{.name}}さん',
  'Greetings': 'こんにちは',
  'kit.error-alert.copied': 'コピーされました',
  'kit.error-alert.detail': 'エラーメッセージをコピーする',
  'locale.english': '英語',
  'locale.japan': '日本語',
  'No data': 'データなし',
  'org.ai-param.ai-model-duplicate': 'AIモデルはすでに存在します',
  'org.ai-param.ai-model.error': 'AIモデルはすでに存在します',
  'org.ai-param.ai-model.field': 'AIモデル',
  'org.ai-param.ai-model.placeholder': 'モデルを選択してください',
  'org.ai-param.copy.title': 'デフォルトパラメータをコピーする',
  'org.ai-param.copy.action': 'デフォルトのパラメータを使用する',
  'org.ai-param.copy.hint': 'このアクションにより、デフォルトの AI モデル パラメーターが組織に追加されます。 AI モデルのパラメーターがすでに存在する場合、それらは変わりません。続行しますか?',
  'org.ai-param.create.action': 'パラメータの追加',
  'org.ai-param.create.error': 'AI パラメータを作成できませんでした',
  'org.ai-param.create.submit': '作成',
  'org.ai-param.create.title': 'パラメータの追加',
  'org.ai-param.delete.action': '削除',
  'org.ai-param.delete.cancel': 'キャンセル',
  'org.ai-param.delete.error': 'AI パラメータを削除できませんでした',
  'org.ai-param.delete.hint': 'このアクションにより、この AI モデル パラメーターが組織から削除されます。この操作は元に戻すことができません。続行しますか?',
  'org.ai-param.delete.submit': '確認する',
  'org.ai-param.delete.title': 'AIパラメータの削除',
  'org.ai-param.diameter.field': '直径',
  'org.ai-param.display-name': '表示名',
  'org.ai-param.display-name.empty': '無名ルール',
  'org.ai-param.equipment-class-duplicate': '設備クラスはすでに存在します',
  'org.ai-param.form.cancel': 'キャンセル',
  'org.ai-param.guideline.1': 'たとえば、ルールは、接続先のヘッドの数に基づいて、消火パイプの一連の直径を定義できます。',
  'org.ai-param.guideline.2': 'メンバーはデフォルトのパラメータをコピーしてより速く開始したり、AI パラメータを手動で作成したりできます。',
  'org.ai-param.guideline': 'AI パラメータは、組織内の AI モデル用に事前定義されたパラメータです。',
  'org.ai-param.hydrant-rule.equipment': '設備',
  'org.ai-param.hydrant-rule.exchange-value': '交換価値',
  'org.ai-param.hydrant-rule.title': '消火栓ルール',
  'org.ai-param.invalid': '無効',
  'org.ai-param.list.ai-model': 'AIモデル',
  'org.ai-param.list.diameter': '直径',
  'org.ai-param.list.num-head': 'ヘッドの数',
  'org.ai-param.list.range {{.from}} {{.to}}': '{{.from}}から{{.to}}まで',
  'org.ai-param.list.title': 'ルールブック',
  'org.ai-param.manual.action': '新パラメータを作成する',
  'org.ai-param.max.field': '最大',
  'org.ai-param.min.field': '最小',
  'org.ai-param.overlap': '重なり',
  'org.ai-param.required': '必須',
  'org.ai-param.rule.equipment.error': '設備クラスはすでに存在します',
  'org.ai-param.rule.equipment.field': '設備',
  'org.ai-param.rule.title': 'ルール',
  'org.ai-param.update.action': '編集',
  'org.ai-param.update.error': 'AIパラメータを更新できませんでした',
  'org.ai-param.update.submit': '更新',
  'org.ai-param.update.title': 'AIパラメータの更新',
  'org.construction.title': '案件一覧',
  'org.field.address': '住所',
  'org.field.email.hint': '招待リンクをメールでお送りします。',
  'org.field.email.rules.1': '「オーナー」はメンバーを追加したり、削除したり、組織の情報を修正したりすることができます。',
  'org.field.email.rules.2': '「メンバー」は案件を作成したり、招待された案件を参加したりすることができます。',
  'org.field.email.rules': '組織のロール：',
  'org.field.email': 'メールアドレス',
  'org.field.name': '企業名',
  'org.field.phone': '代表電話番号',
  'org.field.tax-code': '法人番号',
  'org.invitation.create.error.help': '入力内容に誤りがあります。もう一度ご入力ください。',
  'org.invitation.create.error.title': 'ユーザーを招待できませんでした。',
  'org.invitation.create.role-member': 'あなたの権限では他のメンバーを招待できません。組織のオーナーに依頼してください。',
  'org.invitation.create.submit': '招待する',
  'org.invitation.create.title': 'ユーザーを招待する',
  'org.invitation.expired': '有効期限',
  'org.invitation.invite.guideline.1': '「オーナー」は、メンバーの追加または削除など、組織の情報を編集できます。',
  'org.invitation.invite.guideline.2': '「メンバー」は、新しい案件を追加したり、招待された案件に参加することができます。',
  'org.invitation.invite.guideline': 'ユーザーを招待します:',
  'org.invitation.invited': '招待日',
  'org.invitation.list.invite': 'ユーザーを招待',
  'org.invitation.list.title': '招待済みユーザー一覧',
  'org.invitation.remove': '除外',
  'org.invite.member.field.locale.hint': 'メールやアプリではこの言語を使用します。 いつでも言語を変更できます。',
  'org.member.remove.cancel': 'キャンセル',
  'org.member.remove.error.title': 'メンバーを除外できませんでした。',
  'org.member.remove.helper {{.email}}': 'この組織から {{.email}} を除外してもよろしいですか?',
  'org.member.remove.helper.2': '組織からメンバーを除外すると、全ての案件から削除されます。組織からメンバーを除外せずに特定の案件からメンバーを削除することもできます。',
  'org.member.remove.helper.3': '再度招待することはできますが、必要に応じて案件に再度追加する必要があります。',
  'org.member.remove.submit': '除外する',
  'org.member.remove.title': 'メンバーを組織から除外されますか?',
  'org.member.revoke.invitation.helper {{.email}}': '{{.email}} の招待を削除してもよろしいですか?',
  'org.member.revoke.invitation.helper.2': '招待メールのリンクが無効になります。',
  'org.member.revoke.invitation.helper.3': '今後再度招待できます。',
  'org.member.revoke.invitation.submit': '削除する',
  'org.member.revoke.invitation.title': '招待を削除されますか？',
  'org.member.table.remove': '除外',
  'org.member.title': 'メンバー数',
  'org.member.unit': 'メンバー',
  'org.overview.organization.edit': '編集',
  'org.overview.organization.go': '組織へ',
  'org.preset.edit': '編集',
  'org.preset.guideline.1': '例えば：プリセットでは、資材の「品目」、「品名」、「サイズリスト」などを事前設定することができます。',
  'org.preset.guideline.2': '部材のプロパティを簡単に選択できるように、案件作成の時に適切なプリセットを選択します。',
  'org.preset.guideline': 'プリセットは、組織ごとに事前設定されたマスターデータです。',
  'org.preset.label': 'プリセット',
  'org.preset.property.create': 'プロパティ作成',
  'org.preset.title': 'プリセット',
  'org.property.update-successfully': '資材のプロパティを更新しました。',
  'org.role.label': '権限',
  'org.role.member.title': 'メンバー',
  'org.role.owner.title': 'オーナー',
  'org.stats.constructions': '案件',
  'org.stats.constUnit': '件',
  'org.stats.maxNumConstruction': '最大件数',
  'org.stats.maxOverPage': 'オーバーページ',
  'org.stats.numAdditionalAskAI': '超過AI利用回数',
  'org.stats.numAdditionalDownload': '超過ダウンロード回数',
  'org.stats.numConstruction': '件数',
  'org.stats.numOverPage': 'Number over page',
  'org.stats.numPagePerSeat': 'ページ数／席',
  'org.stats.numSeat': '席数',
  'org.stats.page': 'ページ',
  'org.stats.pagePoolCapacity': '総ページ数',
  'org.stats.pageUnit': 'ページ',
  'org.stats.pageUsed': '利用済みページ数',
  'org.stats.standardPlan': 'スタンダードプラン',
  'org.stats.storage': 'ストレージ',
  'org.stats.storageCapacity': 'ストレージ容量',
  'org.stats.storageUsed': '利用済みストレージ',
  'org.stats.title': '利用状況',
  'org.title': '組織',
  'org.update.error.help': '入力内容に誤りがあります。もう一度ご入力ください。',
  'org.update.error.title': '組織を更新できませんでした。',
  'org.update.submit': '更新する',
  'org.update.title': '組織情報を更新する',
  'otp.back': '戻る',
  'otp.cancel': 'キャンセル',
  'otp.field.otp.error': 'エラー',
  'otp.field.otp.help': '認証コード',
  'otp.field.otp.invalid': '無効なワンタイムパスワード',
  'otp.field.otp': 'ワンタイムパスワード',
  'otp.help': 'Google Authenticatorを用いてQRコードを読み取り、表示されたワンタイムパスワードを入力してください',
  'otp.next': '次へ',
  'otp.submit': '２要素認証を有効にする',
  'otp.title': '２要素認証を有効にする',
  'page-not-found': '404エラー　アクセスしようとしたページは見つかりませんでした',
  'page.action.add.pages': '図面追加',
  'page.action.more': 'メニュー',
  'page.add.after.page.title': 'このページの後ろに追加',
  'page.add.before.page.title': 'このページの前に追加',
  'page.add.error.title': 'このページが追加できません',
  'page.delete.cancel': 'キャンセル',
  'page.delete.error.title': 'このページが削除できません',
  'page.delete.helper.1': 'このページの拾いデータも削除されます。',
  'page.delete.helper.2': '拾いデータを保持したままページを差し替えたい場合は、「その他」をクリックしてください。',
  'page.delete.helper.3': ' 削除した後は戻すことができます',
  'page.delete.helper': 'このページを削除してもよろしいですか？',
  'page.delete.multiple.title': 'ページ削除',
  'page.delete.submit': '削除',
  'page.delete.title': 'ページ削除',
  'page.duplicate.action.cancel': 'キャンセル',
  'page.duplicate.action.submit': '複製',
  'page.duplicate.error.title': 'このページが複製できません',
  'page.duplicate.help': 'このページを複製しますか?',
  'page.duplicate.title': 'ページ複製',
  'page.label': 'ページ',
  'page.list.title': '図面一覧',
  'page.overview.annotate': 'AI積算',
  'page.overview.draw': '作図',
  'page.overview.more.delete': '削除',
  'page.overview.more.duplicate': 'コピーを作成',
  'page.overview.more.rename': '名称を変更',
  'page.overview.more.replace': '差し替え',
  'page.print.category.helper.1': 'この種目に全ての拾いした図面をPDFに出力します.',
  'page.print.failed': '図面が印刷できません',
  'page.print.helper.1': 'このページを出力します',
  'page.print.helper.2': '自動的にPDFをダウンロードします',
  'page.print.helper.3': '”出力”をクリックしてください',
  'page.print.helper.creating_pdf': 'PDF作成中',
  'page.print.page.label': 'ページ',
  'page.print.page.title': '拾い図出力',
  'page.print.pages.title': '拾い図出力',
  'page.print.submit': '出力',
  'page.print.title': 'ページ出力',
  'page.rename.cancel': 'キャンセル',
  'page.rename.error.title': 'このページ名が修正できません',
  'page.rename.error': '名前を変更できませんでした',
  'page.rename.submit': '変更する',
  'page.rename.title': '名称を変更する',
  'page.replace.error.title': 'このページが差し替えできません',
  'page.replace.error': '差し替えれませんでした',
  'page.replace.field.file.hint': 'PDFファイルを選択してください。',
  'page.replace.field.file.label': '差し替え',
  'page.replace.field.page.hint': 'PDFファイルから使用したいページを入力してください。',
  'page.replace.field.page': 'ページ',
  'page.replace.submit': '差し替える',
  'page.replace.title': '差し替える',
  'page.upload.cancel': 'キャンセル',
  'page.upload.field.file.hint': 'PDFファイルをアップロードしてください。容量が250MB以下のPDFファイルを選択してください',
  'page.upload.field.file': '図面',
  'page.upload.options.allPages': '全てのページ',
  'page.upload.options.specificPages': '追加したいページを選択してください',
  'page.upload.rules': 'ページが後ろに追加されます。具体的なページに追加したい場合は「その他」をクリックしてください。',
  'page.upload.specificPages.hint': '「,」で区切ってページを入力してください',
  'page.upload.submit': 'アップロードする',
  'page.upload.title': '図面をアップロードする',
  'preset.action.delete': '削除',
  'preset.create.error.title': 'このプリセットが修正できません',
  'preset.delete.error.title': 'プリセットが削除できません',
  'preset.edit.error.title': 'このプリセットが修正できません',
  'preset.equipment.properties': '資材のプロパティ',
  'preset.equipment.property.copy.error': 'このプロパティがコピーできません',
  'preset.equipment.property.create.form.error.title': 'プロパティが作成できません',
  'preset.equipment.property.create': 'プロパティ作成',
  'preset.equipment.property.delete.error': 'このプロパティが削除できません',
  'preset.equipment.property.label': 'プロパティ',
  'preset.equipment.property.tree.copy': '他の部材にコピー',
  'preset.equipment.property.tree.create': '作成',
  'preset.equipment.property.tree.delete': '削除',
  'preset.equipment.property.tree.save': '保存',
  'preset.equipment.property.update.form.error.title': 'プロパティが更新できません',
  'preset.type.global_preset': '参考データ',
  'preset.type.org_preset': 'カスタムプリセット',
  'report-fire.field.category': '設備名：',
  'report-fire.field.construction': '件名：',
  'report-fire.header.construction_area': '施工種別',
  'report-fire.header.equipment': '資材',
  'report-fire.header.material': '管材',
  'report-fire.header.sub-total': '小計',
  'report-fire.header.total': '合計',
  'report-fire.tab.pieces': '個数物_集計表',
  'report-fire.tab.pipes': '配管_集計表',
  'report.download.format_csv': 'CSV 形式',
  'report.download.format_excel': 'Excel 形式',
  'report.download': 'ダウンロード',
  'report.field.category': '工事種目',
  'report.field.construction_code': '案件コード',
  'report.field.construction_name': '案件名称',
  'report.field.date': '日付',
  'report.field.page': 'ページ',
  'report.header.construction_area': '施工箇所',
  'report.header.insulation': '保温',
  'report.header.material': '材料',
  'report.header.object': '部材',
  'report.header.size': 'サイズ',
  'report.header.total': '集計',
  'report.header.type': 'タイプ',
  'report.header.unit': '単位',
  'report.insulation.non': 'なし',
  'report.module.none': '系統なし',
  'report.summary': '集計表',
  'report.template': '書式',
  'report.title.summary': '集計表',
  'report.type.fd': 'FD付き',
  'report.type.nonfd': 'FDなし',
  'report.unit.piece': '個',
  'reset-password.accept.form.error.help': '入力内容に誤りがありました。もう一度入力ください。',
  'reset-password.accept.form.error.title': '入力されたメールアドレスが存在しません',
  'reset-password.accept.form.help': '登録したメールアドレスを入力してください。',
  'reset-password.accept.form.submit': 'パスワード作成',
  'reset-password.accept.success.go': 'ログインに移動する',
  'reset-password.accept.success.help': 'ログインしてください',
  'reset-password.contact-support': 'サポートに連絡する',
  'reset-password.email.sent.description': 'パスワード再設定用URLがメールを送信しました',
  'reset-password.email.sent.go-back': '戻る',
  'reset-password.email.sent.help.1': '場合によっては、メールが届くまでに少し時間がかかったり、迷惑メールフォルダーに入ってしまったりすることがあります',
  'reset-password.email.sent.help.2': '別のメールアドレスを使用した場合は、戻って再度入力してください',
  'reset-password.email.sent': 'メールをご確認ください',
  'reset-password.error-404.help': 'アカウントが存在しません。入力内容を確認して、もう一度お試しください',
  'reset-password.error-423.help': '無効なパスワード試行によりアカウントがロックされています',
  'reset-password.error.help': '入力内容に誤りがありました。もう一度入力ください。',
  'reset-password.error.title': 'パスワードを変更できませんした',
  'reset-password.need-assistance': 'パスワードを忘れた方',
  'reset-password.remember-password': 'パスワードを忘れないようにしてください',
  'reset-password.submit': '送信する',
  'reset-password.title': 'パスワードの変更',
  'sign-in.error-401.help {{.remaining}}': 'メールアドレスまたはパスワードが間違っています。アカウントがロックされるまで、あと {{.remaining}} 回の試行が残っています。',
  'sign-in.error-404.help': 'アカウントが見つかりません。入力内容を確認して、もう一度お試しください',
  'sign-in.error-423.help': '無効なパスワード試行によりアカウントがロックされています。サポートについてはご連絡ください.',
  'sign-in.otp.error.help': '無効なワンタイムパスワードを入力されました。もう一度ご入力ください。',
  'sign-in.otp.error.title': 'エラー',
  'sign-in.otp.field.otp': '認証コード',
  'sign-in.otp.help': '6桁の認証コードをご入力ください',
  'sign-in.otp.verification.title': '本人確認',
  'sign-in.reset': 'パスワードをお忘れの方はこちら',
  'sign-in.self.error.help': '入力内容に誤りがありました。もう一度ご入力ください。',
  'sign-in.self.error.title': 'ログイン失敗',
  'sign-in.submit': 'ログインする',
  'sign-in.title': 'ログインする',
  'sign-in.trouble_sign_in': '何か問題がありますか?',
  'sign-up.accept.error.help.1': 'エラーが発生しました。 招待リンクの有効期限が切れている可能性があります',
  'sign-up.accept.error.help.2': '新しい招待状を取得するには、送信者に再送信するよう依頼してください',
  'sign-up.accept.error': 'エラーを発生しました',
  'sign-up.accept.form.error.help': '入力内容に誤りがありました。もう一度ご入力ください。',
  'sign-up.accept.form.error.title': '組織へ参加できませんでした。',
  'sign-up.accept.form.submit': '招待を受ける',
  'sign-up.accept.form.title {{.orgName}}': '{{.orgName}}へ参加する',
  'sign-up.accept.success.go': 'ログインへ移動',
  'sign-up.accept.success.help': 'ログインしてください',
  'sign-up.accept.success.title {{.orgName}}': '{{.orgName}}にようこそ',
  'table.no.data': 'データなし',
  'Welcome back': 'ログインする',
  'report.header.non-zone': '区分なし',
  'annot.color.blue': '青い',
  'annot.color.green': '緑',
  'annot.color.lightBlue': '薄青',
  'annot.color.lightRed': '薄赤',
  'annot.color.lightViolet': '薄紫',
  'annot.color.orange': 'オレンジ',
  'annot.color.red': '赤い',
  'annot.color.violet': '紫',
  'annot.color.yellow': '黄色',
  'construction.zone.create': '区分追加',
  'construction.zone.update': '更新',
  'construction.zone.name': '区分名称',
  'construction.zone.color': '色',
  'construction.zone.list': '区分リスト',
  'construction.zone.create-error': '区分が追加できません',
  'construction.zone.update-error': '区分が更新できません',
  'construction.zone.update-title': '更新',
  'category.report.template': 'テンプレートレポート',
  'category.report.template-default': 'デフォルト',
  'page.file-upload.error.500': 'サーバーのエラーが発生しました。もう一度お試しください。',
  'page.file-upload.error.400': 'ファイルの容量が250MBを超えているか、形式が正しくありません。別のファイルを選択してください',
  'page.file-upload.error.413': 'ファイルの容量が250MBを超えているか、形式が正しくありません。別のファイルを選択してください',
}
